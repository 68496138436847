<template>
  <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
    <v-layout align-center>
      <v-icon class="pr-3 white--text" large>{{ snackbar.icon }}</v-icon>
      <v-layout column>
        <div>
          <strong>{{ snackbar.title }}</strong>
        </div>
        <div>{{ snackbar.text }}</div>
      </v-layout>
      <v-btn v-if="snackbar.timeout === 0" icon @click="clearSnack(); snackbar.visible = false">
        <v-icon>{{ close }}</v-icon>
      </v-btn>
    </v-layout>
  </v-snackbar>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiAlertCircle, mdiAlertOutline, mdiInformationOutline, mdiCheckCircleOutline, mdiCloseCircleOutline } from '@mdi/js'
export default {
  data() {
    return {
      close: mdiCloseCircleOutline,
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 3000,
        title: null,
        visible: false
      },
      timeout: 3000
    };
  },
  methods: {
    ...mapActions("snackbar", ['clearSnack']),
    show(type, message) {
      if (!type) return;
      switch (type) {
        case "error":
          this.snackbar = {
            color: "error",
            icon: mdiAlertCircle,
            mode: "multi-line",
            position: "top",
            timeout: 3000,
            title: "Error",
            text: message,
            visible: true
          };
          break;
        case "info":
          this.snackbar = {
            color: "info",
            icon: mdiInformationOutline,
            mode: "multi-line",
            position: "top",
            timeout: 3000,
            title: "Information",
            text: message,
            visible: true
          };
          break;
        case "success":
          this.snackbar = {
            color: "success",
            icon: mdiCheckCircleOutline,
            mode: "multi-line",
            position: "top",
            timeout: 3000,
            title: "Success",
            text: message,
            visible: true
          };
          break;
        case "warning":
          this.snackbar = {
            color: "warning",
            icon: mdiAlertOutline,
            mode: "multi-line",
            position: "top",
            timeout: 3000,
            title: "Warning",
            text: message,
            visible: true
          };
          break;
      }
    }
  },
  watch: {
    '$store.state.snackbar.message': function() {
      if(this.$store.state.snackbar.message) {
        this.show(this.$store.state.snackbar.type ?? 'error', this.$store.state.snackbar.message);
      }
    },
    'snackbar.visible': function() {
      if(!this.snackbar.visible) {
        this.clearSnack();
      }
    }
  }
};
</script>