import * as API from "@/services/API";

export default {
  getProjects(search = '', options = {page: 1, itemsPerPage: 10}) {
    let url = `/projects?page=${options.page}&per_page=${options.itemsPerPage}&search=${search}`;
    if(options.sortBy && options.sortBy.length > 0) {
      url += `&sort_by=${options.sortBy[0]}&sort_desc=${options.sortDesc[0] ? '1' : '0'}`
    }
    return API.apiClient.get(url);
  },
  getProject(id) {
    return API.apiClient.get(`/projects/${id}`);
  },
  getProjectWithDetails(id) {
    return API.apiClient.get(`/projects/${id}/details`);
  },
  loadFilterProjects() {
    return API.apiClient.get(`/projects/filter`);
  },
  store(data) {
    return API.apiClient.post(`/projects`, data);
  },
  update(id, data) {
    return API.apiClient.post(`/projects/${id}`, data);
  },
  updateSetting(id, key, value) {
    return API.apiClient.post(`/projects/${id}/setting`, {
      key: key,
      value: value
    });
  },
  setSearchConsoleProperty(id, token_id, property) {
    return API.apiClient.post(`/projects/${id}/search-console`, {
      token_id: token_id,
      property: property
    });
  },
  setAnalyticsProperty(id, token_id, property) {
    return API.apiClient.post(`/projects/${id}/analytics`, {
      token_id: token_id,
      property: property
    });
  },
  enableWPEditing(id, password) {
    return API.apiClient.post(`/projects/${id}/enable-wp-editing`, {
      application_password: password
    });
  },
  disableWPEditing(id) {
    return API.apiClient.post(`/projects/${id}/disable-wp-editing`);
  },
  enableLogAnalysis(id, host, port, username, password, path, hosting_provider) {
    return API.apiClient.post(`/projects/${id}/enable-log-analysis`, {
      host: host,
      port: port,
      username: username,
      password: password,
      path: path,
      hosting_provider: hosting_provider
    });
  },
  enableDirectAccessLogAnalysis(id, access_log_url, error_log_url, hosting_provider) {
    return API.apiClient.post(`/projects/${id}/enable-direct-access-log-analysis`, {
      access_log_url: access_log_url,
      error_log_url: error_log_url,
      hosting_provider: hosting_provider
    });
  },
  disableLogAnalysis(id) {
    return API.apiClient.post(`/projects/${id}/disable-log-analysis`);
  },
  clearLastScanDate(id) {
    return API.apiClient.post(`/projects/${id}/clear-last-scan`);
  },
  getFirstPerformanceRecordForProject(id) {
    return API.apiClient.get(`/projects/${id}/first-performance-record`);
  },
  getFirstKeywordRecordForProject(id) {
    return API.apiClient.get(`/projects/${id}/first-keyword-record`);
  },
  extractAdditionalSCDataForProject(id, start_date, end_date) {
    return API.apiClient.post(`/projects/${id}/schedule-data-extraction`, {
      start_date: start_date,
      end_date: end_date
    });
  },
  extractAdditionalKeywordDataForProject(id, start_date, end_date) {
    return API.apiClient.post(`/projects/${id}/schedule-keyword-data-extraction`, {
      start_date: start_date,
      end_date: end_date
    });
  },
  delete(id) {
    return API.apiClient.delete(`/projects/${id}`);
  },
  regenerateToken(id) {
    return API.apiClient.post(`/projects/${id}/regenerate-token`);
  }
};
