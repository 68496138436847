export const namespaced = true;

export const state = () => ({
  message: '',
  type: 'error'
})

export const mutations = {
  SET_MESSAGE(state, message) {
    state.message = message;
  },
  SET_TYPE(state, type) {
    state.type = type;
  },
};

export const actions = {
  setSnack({ commit }, {message, type}) {
    commit("SET_MESSAGE", message);
    commit("SET_TYPE", type);
  },
  clearSnack({ commit }) {
    commit("SET_MESSAGE", '');
    commit("SET_TYPE", 'error');
  },
}