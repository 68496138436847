import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VuetifyConfirm from 'vuetify-confirm'
import router from './router'
import store from './store'
import axios from "axios"

import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'

import { mapState, mapActions } from "vuex";

Vue.config.productionTip = false

//event bus
window.bus = new Vue({});

Vue.mixin({
  computed: {
    ...mapState("auth", ["user"]),
    app_title() {
      return process.env.VUE_APP_TITLE;
    },
    hasHistory () { return window.history.length > 2 }
  },
  methods: {
    throttle: function (fn, ms) {
      if (this.delay_timer) {
        window.clearTimeout(this.delay_timer);
      }
      this.delay_timer = window.setTimeout(fn, ms);
    },
    can: function (ability) {
      return this.user && this.user.permissions && this.user.permissions[ability];
    },
    hasRole: function (role) {
      return this.user && this.user.roles.filter((r) => { return r === role; }).length > 0;
    },
    copyToClipboard(str) {
      function listener(e) {
        e.clipboardData.setData("text/html", str);
        e.clipboardData.setData("text/plain", str);
        e.preventDefault();
      }
      document.addEventListener("copy", listener);
      document.execCommand("copy");
      document.removeEventListener("copy", listener);
    },
    decodeAttrs(content) {
      const textArea = document.createElement("textarea");
      textArea.innerHTML = escapeQuote(content);
      return deescapeQuote(textArea.innerText);

      function escapeQuote(html) {
        return html ? html.replace(/&quot;/gi, "__$__") : html;
      }

      function deescapeQuote(html) {
        return html ? html.replace(/__\$__/g, "&quot;") : html;
      }
    },
    handleFileDownload(response, filename = "export.csv") {
      let fileURL = window.URL.createObjectURL(new Blob([response.data]));
      let fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', filename);
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    }
  }
})

Vue.use(VuetifyConfirm, { vuetify })

Vue.use(VueMonacoEditorPlugin, {
  paths: {
    // The recommended CDN config
    vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs'
  },
})

new Vue({
  router,
  store,
  vuetify,
  axios,
  render: h => h(App)
}).$mount('#app')
