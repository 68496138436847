<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        :to="{ name: 'dashboard' }"
        class="d-flex align-center text-decoration-none"
      >
        <!-- <v-img
          :src="require('@/assets/images/logos/eppas.svg')"
          max-height="30px"
          max-width="30px"
          :alt="app_title"
          contain
          eager
          class="app-logo me-3"
        ></v-img> -->
        <v-icon class="app-logo me-3">
          mdi-radar
        </v-icon>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            {{ app_title }}
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        icon="mdi-home-outline"
      ></nav-menu-link>
      <v-list v-if="can('view task dashboard') || can('view page change dashboard') || can('view published articles dashboard')" dense>
        <v-list-item-group color="primary">
          <v-list-item v-if="can('view task dashboard')" class="ml-6 py-0 submenu" :to="{name: 'task_overview'}">
            <v-list-item-icon>
              <v-icon small>mdi-calendar-month-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Task Overview</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="can('view page change dashboard')" class="ml-6 py-0 submenu" :to="{name: 'page_change_overview'}">
            <v-list-item-icon>
              <v-icon small>mdi-file-document-edit-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Page Changes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="can('view published articles dashboard')" class="ml-6 py-0 submenu" :to="{name: 'published_articles_overview'}">
            <v-list-item-icon>
              <v-icon small>mdi-file-document-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Published Articles</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <!-- <nav-menu-group
        title="Pages"
        icon="mdi-file-outline"
      >
        <nav-menu-link
          title="Login"
          :to="{ name: 'pages-login' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Register"
          :to="{ name: 'pages-register' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Error"
          :to="{ name: 'error-404' }"
          target="_blank"
        ></nav-menu-link>
      </nav-menu-group> -->
      <nav-menu-link
        v-if="can('manage todos')"
        title="ToDo's"
        :to="{ name: 'todos' }"
        icon="mdi-format-list-checks"
      ></nav-menu-link>
      <nav-menu-link
        v-if="can('view projects')"
        title="Projects"
        :to="{ name: 'projects' }"
        icon="mdi-file-cabinet "
      ></nav-menu-link>
      <nav-menu-link
        v-if="can('manage authors')"
        title="Authors"
        :to="{ name: 'authors' }"
        icon="mdi-account-group "
      ></nav-menu-link>
      <nav-menu-link
        v-if="can('manage users')"
        title="Users"
        :to="{ name: 'users' }"
        icon="mdi-account-multiple "
      ></nav-menu-link>
      <nav-menu-link
        v-if="can('use tools')"
        title="Tools"
        :to="{ name: 'tools' }"
        icon="mdi-tools"
      ></nav-menu-link>
      <!-- <nav-menu-link
        title="Pages"
        :to="{ name: 'pages' }"
        :icon="icons.mdiFileMultipleOutline "
      ></nav-menu-link> -->

      <v-list dense v-if="$store.state.project.project_id" class="pt-8">
        <v-subheader inset class="text-subtitle-1 text--secondary font-weight-semibold">Project Views</v-subheader>
        <v-list-item-group color="primary">
          <v-list-item v-if="can('view projects')" :to="{ name: 'project_details', params: {id: $store.state.project.project_id } }">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title title="Project Details">Project Details</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="can('use pages view')" :to="{ name: 'pages', query: { project_id: $store.state.project.project_id } }">
            <v-list-item-icon>
              <v-icon>mdi-file-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title title="Project Pages">Project Pages</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="can('use crawl view')" :to="{name: 'project_crawl_view', params: {id: $store.state.project.project_id} }">
            <v-list-item-icon>
              <v-icon>mdi-spider-web</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title title="Crawl View">Crawl View</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="can('use keyword explorer')" :to="{name: 'project_keyword_explorer', params: {id: $store.state.project.project_id} }">
            <v-list-item-icon>
              <v-icon>mdi-table-key</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title title="Keyword Explorer">Keyword Explorer</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="can('use low internal links view')" :to="{name: 'project_low_internal_links_view', params: {id: $store.state.project.project_id} }">
            <v-list-item-icon>
              <v-icon>mdi-gauge-low</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title title="Low Internal Links View">Low Internal Links View</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item v-if="can('use seo view')" :to="{name: 'project_seo_view', params: {id: $store.state.project.project_id} }">
            <v-list-item-icon>
              <v-icon>mdi-home-search-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>SEO Tools</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-group v-if="can('use seo view')" :value="true" prepend-icon="mdi-home-search-outline">
            <template v-slot:activator>
              <v-list-item-title title="SEO Tools">SEO Tools</v-list-item-title>
            </template>

            <v-list-item class="ml-6 py-0 submenu" :to="{name: 'project_seo_view', params: {id: $store.state.project.project_id} }">
              <v-list-item-icon>
                <v-icon small>mdi-monitor-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title title="Overview">Overview</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="can('use rank checker')" class="ml-6 py-0 submenu" :to="{name: 'project_rank_checker', params: {id: $store.state.project.project_id} }">
              <v-list-item-icon>
                <v-icon small>mdi-finance</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title title="Rank Tracker">Rank Tracker</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="can('use reports')" class="ml-6 py-0 submenu" :to="{name: 'project_report_listing', params: {id: $store.state.project.project_id, type: 'keyword-cannibalization' } }">
              <v-list-item-icon>
                <v-icon small>mdi-pac-man</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title title="Keyword Cannibalization">Keyword Cannibalization</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="can('use log analysis') && hasRole('Admin')" class="ml-6 py-0 submenu" :to="{name: 'project_log_analysis', params: {id: $store.state.project.project_id } }">
              <v-list-item-icon>
                <v-icon small>mdi-poll</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title title="Log Analysis">Log Analysis</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-if="can('use editorial view')" :to="{name: 'project_editorial_view', params: {id: $store.state.project.project_id} }">
            <v-list-item-icon>
              <v-icon>mdi-table-of-contents</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title title="Editorial View">Editorial View</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="can('use content authority view')" :to="{name: 'project_content_authority_view', params: {id: $store.state.project.project_id} }">
            <v-list-item-icon>
              <v-icon>mdi-police-badge-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title title="Content Authority View">Content Authority View</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="can('use reports')" :to="{name: 'project_reports', params: {id: $store.state.project.project_id} }">
            <v-list-item-icon>
              <v-icon>mdi-chart-tree</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title title="Reports">Reports</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
        </v-list-item-group>
      </v-list>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

//import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    //NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {

  .submenu {
    height: 25px;
    min-height: 25px;
  }
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
