import * as API from "@/services/API";

export default {
  list() {
    return API.apiClient.get(`/notifications`);
  },
  delete(id) {
    return API.apiClient.delete(`/notifications/${id}`);
  },
  clear() {
    return API.apiClient.delete(`/notifications/all`);
  },
  markAsSeen() {
    return API.apiClient.post(`/notifications/mark-as-seen`);
  },
  markAsVisited(id) {
    return API.apiClient.post(`/notifications/${id}/mark-visited`);
  },
};
