<template>
  <v-app>
    <component :is="resolveLayout">
      <router-view></router-view>
    </component>
    <Snackbar />
  </v-app>
</template>

<script>
import { computed } from 'vue'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import Snackbar from '@/components/Snackbar.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    Snackbar
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  }
}
</script>
