export const namespaced = true;

import PagesService from "@/services/PagesService";

export const state = () => ({
  tags: null,
})

export const mutations = {
  SET_TAGS(state, tags) {
    state.tags = tags;
  },
};

export const actions = {
  setTags({ commit }, tags) {
    commit("SET_TAGS", tags);
  },
  refreshTags({ commit }, page_id) {
    PagesService.getAllTags(page_id)
    .then((response) => {
      commit("SET_TAGS", response.data.tags);
    })
    .catch((error) => {
      console.log('Error fetching tags: ', error);
    });
  }
}