import Vue from 'vue'
import Vuex from 'vuex'

import * as auth from "@/store/modules/auth";
import * as snackbar from "@/store/modules/snackbar";
import * as project from "@/store/modules/project";
import * as tags from "@/store/modules/tags";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    snackbar,
    project,
    tags
  }
})
