<template>
  <v-menu v-model="menu" offset-y left nudge-bottom="0" min-width="400" max-width="400" content-class="notification-menu-content">
    <template v-slot:activator="{ on, attrs }">
      <v-badge color="error" overlap offset-x="8" offset-y="8" dot :value="new_count > 0">
        <v-btn icon small class="ms-3" v-bind="attrs" v-on="on">
          <v-progress-circular v-if="loading" indeterminate color="primary" size="20" width="3"></v-progress-circular>
          <v-icon v-else>
            mdi-bell-outline
          </v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-title class="text-xl">Notifications</v-list-item-title>
         <v-list-item-action >
          <v-btn v-if="notifications && notifications.length > 0" x-small outlined @click.prevent.stop="clear()" color="error" class="mr-6">
            Clear All
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <template v-if="notifications && notifications.length > 0">
        <div v-for="notification in notifications" :key="'notification_' + notification.id">
          <v-divider class="my-2"></v-divider>
          <v-list-item link two-line class="py-2" :to="notification.url" @click="markAsVisited(notification)" :style="notification.visited ? 'opacity: 0.65;' : 'opacity: inherit;'">
            <v-list-item-content :title="notification.description">
              <v-list-item-title v-html="notification.title"></v-list-item-title>
              <v-list-item-subtitle v-html="notification.description"></v-list-item-subtitle>
            </v-list-item-content>
            <v-badge color="success" overlap dot offset-x="8" offset-y="16" :value="!notification.seen">
              <v-list-item-action>
                <v-btn icon @click.prevent.stop="remove(notification)">
                  <v-icon color="grey lighten-1">mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-badge>
          </v-list-item>
        </div>
      </template>
      <template v-else>
        <v-divider class="my-2"></v-divider>
        <v-list-item two-line class="py-2">
          <v-list-item-content>
            There are currently no notifications to display.
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>

import NotificationService from "@/services/NotificationService";
import error_helper from "@/utils/error_helper";

export default {
  mixins: [error_helper],
  data() {
    return {
      loading: false,
      menu: false,
      notifications: null,
      timer: null,
      seen_timer: null,
    }
  },
  created() {
    this.loadData();
    this.timer = setInterval(this.loadData, 5 * 60 * 1000);
  },
  beforeDestroy() {
    if(this.timer) {
      clearInterval(this.timer);
    }
  },
  computed:{
    new_count() {
      if(this.notifications && this.notifications.length > 0) {
        return this.notifications.filter((n) => n.seen == false).length;
      }
      return 0;
    }
  },
  methods: {
    loadData() {
      this.loading = true;
      NotificationService.list()
        .then((response) => {
          this.notifications = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.handleErrors(error);
          this.loading = false;
        });
    },
    remove(notification) {
      NotificationService.delete(notification.id)
        .then((response) => {
          this.notifications.splice(this.notifications.indexOf(notification), 1);
        })
        .catch((error) => {
          this.handleErrors(error);
        });
    },
    clear() {
      NotificationService.clear()
        .then((response) => {
          this.notifications = null;
        })
        .catch((error) => {
          this.handleErrors(error);
        });
    },
    markAsSeen() {
      NotificationService.markAsSeen()
        .then((response) => {
          this.notifications.forEach(element => {
            element.seen = true;
          });
        })
        .catch((error) => {
          this.handleErrors(error);
        });
    },
    markAsVisited(notification) {
      NotificationService.markAsVisited(notification.id)
        .then((response) => {
          notification.visited = true;
        })
        .catch((error) => {
          this.handleErrors(error);
        });
    }
  },
  watch: {
    menu() {
      if(this.menu) {
        this.seen_timer = setTimeout(this.markAsSeen, 5 * 1000);
      } else {
        if(this.seen_timer) {
          clearTimeout(this.seen_timer);
        }
      }
    }
  }
}
</script>

<style lang="scss">
.notification-menu-content {
  .v-list-item {
    min-height: 3rem !important;
  }
}
.theme--light.v-list-item--active::before, .theme--dark.v-list-item--active::before {
  opacity: 0;
}
</style>
