export const namespaced = true;

export const state = () => ({
  projects: null,
  project_id: null,
})

export const mutations = {
  SET_PROJECT(state, project_id) {
    state.project_id = project_id;
  },
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },
};

export const actions = {
  setProject({ commit }, project_id) {
    commit("SET_PROJECT", project_id);
  },
  setProjects({ commit }, projects) {
    commit("SET_PROJECTS", projects);
  },
}