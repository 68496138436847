function handleCanAttribute(to, store, next) {
  if (to.meta.can) {
    const user = store.getters["auth/authUser"];
    if (user && user.permissions && user.permissions[to.meta.can]) {
      next();
    } else {
      next({ name: "notFound" });
    }
  } else {
    next();
  }
}

export default function auth({ to, next, store }) {
  const loginQuery = { path: "/login", query: { redirect: to.fullPath } };

  if (!store.getters["auth/authUser"]) {
    store.dispatch("auth/getAuthUser").then(() => {
      if (!store.getters["auth/authUser"]) next(loginQuery);
      else {
        handleCanAttribute(to, store, next);
      }
    });
  } else {
    handleCanAttribute(to, store, next);
  }
}
