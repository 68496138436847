<template>
  <v-fade-transition mode="out-in">
    <v-icon
      :key="dark"
      @click="dark = !dark"
    >
      {{ dark ? 'mdi-weather-sunny' : 'mdi-weather-night' }}
    </v-icon>
  </v-fade-transition>
</template>

<script>
export default {
  data() {
    return {
      dark: false,
    };
  },
  created() {
    if (localStorage.getItem("DarkMode")) {
      const cookieValue = localStorage.getItem("DarkMode") === "true";
      this.dark = cookieValue;
    }
  },
  watch: {
    dark() {
      this.$vuetify.theme.dark = this.dark;
      localStorage.setItem("DarkMode", this.dark);
    },
  },
};
</script>

<style>
</style>
