
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      errors: {},
    }
  },
  methods: {
    ...mapActions("snackbar", ['setSnack']),
    showMessage(message, type = 'error') {
      this.setSnack({message: message, type: type});
    },
    resetErrors() {
      this.errors = [];
      window.bus.$emit("please_wait", false);
    },
    /**
     * Hanlde error objec and display error messages
     *
     * @param {object} error Error bag object
     *
     * @return {void}
     */
    handleErrors(error) {
      this.resetErrors();
      // Assign error messages from the backend
      if (error.response) {
        if (error.response.status == 422) {
          this.errors = Object.assign(
            {},
            this.errors,
            error.response.data.errors
          )
          this.showMessage(error.response.data.message || 'Some error occurred');
        } else if (error.response.status == 302) {
          window.location.href = error.response.data.redirect || '/'
        } else if (error.response.status == 403) {
          this.$router.push('/');
          this.showMessage(error.response.data.message || 'You are not authorized to view this resource.');
        } else {
          this.showMessage(error.response.data.message || 'Some error occurred');
        }
      } else {
        console.log(error);
        this.showMessage('Some error occurred with the request.');
      }
    },
    /**
     * Helper method for checking if reactive property was assigned and has value
     *
     * @param {string} prop Name of the field
     *
     * @return {boolean}
     */
    hasError(prop) {
      return this.errors[prop] || false
    }
  }
};
