import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import middlewarePipeline from "@/router/middlewarePipeline";
import routes from '@/router/routes'

Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
})

const originalPush = router.push
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }

    return Promise.reject(err)
  })
}

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  const context = { to, from, next, store };

  if (!middleware) {
    return next();
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

router.afterEach(async (to) => {

  Vue.nextTick(() => {
    document.title = to.meta.title
      ? to.meta.title + ' - ' + process.env.VUE_APP_TITLE
      : process.env.VUE_APP_TITLE;
  })
})

export default router
